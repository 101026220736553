<template>
  <div class="" style="background-color: transparent; padding-right: 30rem">
    <Calendar ref="CalendarDom" @change="handleChange" @show="handleShow" :loading="loading" :list="list">
      <el-select v-model="school_id" placeholder="请选择" size="small" @change="handleSchoolChange">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </Calendar>
    <OrderDetail :visible.sync="isShowDialog" :id="id" @submit="handleSubmit" @cancel="handleCancel" @accomplish="handleAccomplish"></OrderDetail>
    <Action
      :visible.sync="isShowDialogTexteare"
      :loading="loading"
      :beforeClose="handleBeforeClose"
      @close="handleActionClose"
      @submit="handleActionSubmit"
      title="取消咨询"
      tips="请输入取消原因"
    ></Action>
  </div>
</template>

<script>
import Calendar from '@/components/common/Calendar.vue'
import OrderDetail from '../components/OrderDetail.vue'
import Action from '../components/Action.vue'
import { getApi2, putApi2 } from '@/api'
export default {
	_config:{"route":{"path":"calendar","meta":{"title":"日程安排","keepAlive":true}}},
  name: 'CalendarDetail',
  components: { Calendar, OrderDetail, Action },
  data() {
    return {
      loading: false,
      isShowDialog: false,
      isShowDialogTexteare: false,
      school_id: '',
      id: 0,
      create_type: 0,
      is_day: 0,
      start_day: '',
      end_day: '',
      options: [],
      list: [],
      type: 0,
      remark: ''
    }
  },
  created() {
    this.getSearchConfig()
  },
  methods: {
    getData() {
      this.loading = true
      let params = {
        school_id: this.school_id,
        start_day: this.start_day,
        end_day: this.end_day,
        is_day: this.is_day
      }
      getApi2('/evaluation/appointment/teacher-schedule', params)
        .then(res => {
          this.list = res.map(item => ({
            date: item.day,
            list: item.list
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSearchConfig() {
      getApi2('/evaluation/result/get-range').then(res => {
        const { school } = res
        if (school.length) {
          this.options = school.map(item => ({ label: item.name, value: item.id }))
          this.school_id = this.options[0].value
          if (this.start_day) {
            this.getData()
          }
        }
      })
    },
    handleBeforeClose() {
      return new Promise((resolve, reject) => {
        let data = {
          id: this.id,
          reason: this.remark
        }
        if (this.type === 1) {
          this.loading = true
          putApi2('/evaluation/appointment/cancel', data)
            .then(() => {
              resolve(1)
              this.$message.success('操作成功！')
              this.getData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleActionClose() {
      this.remark = ''
    },
    handleActionSubmit(val) {
      this.remark = val
    },
    handleShow(item) {
      this.id = item.id
      this.isShowDialog = true
    },
    handleSubmit(id) {
      this.create_type = id
      this.type = 0
      let that = this
      this.$confirm('确认后开始本次咨询！', '提示', {
        confirmButtonText: '马上开始',
        cancelButtonText: '再等会儿',
        type: 'warning',
        appendToBody: false
      })
        .then(() => {
          that.loading = true
          putApi2('/evaluation/appointment/begin', { id: that.id })
            .then(res => {
              that.$message.success(`咨询开始！`)
              that.$router.push(`/edu/psychology/reservation/${that.create_type === 1 ? 'detail' : 'detail'}?id=${that.id}&type=${that.create_type}`)
              that.getData()
            })
            .finally(() => {
              that.loading = false
            })
        })
        .catch(action => {})
    },
    handleCancel(id) {
      this.create_type = id
      this.type = 1
      this.isShowDialogTexteare = true
    },
    handleAccomplish() {
      let that = this
      this.$confirm('本次咨询已经结束，请填写咨询后记录，不填写记录内容，会视为未完成咨询哦！', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '马上记录',
        cancelButtonText: '一会儿再去',
        type: 'warning'
      })
        .then(() => {
          that.loading = true
          putApi2('/evaluation/appointment/end', { id: that.id })
            .then(res => {
              that.getData()
              that.$router.push(`/edu/psychology/records/summary?id=${res.id}&back=list`)
            })
            .finally(() => {
              that.loading = false
            })
        })
        .catch(action => {
          if (action === 'cancel') {
            that.loading = true
            putApi2('/evaluation/appointment/end', { id: that.id })
              .then(() => {
                that.getData()
              })
              .finally(() => {
                that.loading = false
              })
          }
        })
    },
    handleChange(date, is_day) {
      this.is_day = is_day
      this.start_day = date[0]
      this.end_day = date[1]
      if (this.school_id) {
        this.getData()
      }
    },
    handleSchoolChange(e) {
      this.school_id = e
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
