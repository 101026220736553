<template>
  <el-dialog class="top-dialog" title="预约详情" :close-on-click-modal="true" :visible="visible" @close="handleColseDialog">
    <div class="inner-info">
      <template v-if="[4, 5, 6, 7].includes(info.psychology_status) || (info.create_type === 2 && info.psychology_status === 1)">
        <div class="status-img"><img :src="`${$OssAddress}s_${info.psychology_status}.png`" alt="咨询状态" /></div>
      </template>
      <el-row class="page-row w150">
        <el-col class="item">
          <div class="inner">
            <div class="label">咨询主题：</div>
            <div class="content">{{ info.theme_name }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">咨询学生姓名：</div>
            <div class="content">{{ info.student_name }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">性别：</div>
            <div class="content">{{ info.gender }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">学号：</div>
            <div class="content">{{ info.student_no }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">行政班：</div>
            <div class="content">{{ info.class_name }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">年级：</div>
            <div class="content">{{ info.grade_name }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">咨询次数：</div>
            <div class="content">{{ info.counseling_count }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">预约咨询时间：</div>
            <div class="content">{{ info.time }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">咨询地点：</div>
            <div class="content">{{ info.chamber_name }}</div>
          </div>
        </el-col>
        <el-col class="item t-c">
          <template v-if="info.psychology_status === 2">
            <el-button debounce type="primary" @click="handleSubmit">开始咨询</el-button>
            <el-button debounce type="" @click="handleCancle">取消咨询</el-button>
          </template>
          <template v-else-if="info.psychology_status === 3">
            <el-button debounce type="primary" @click="handleAccomplish">完成咨询</el-button>
          </template>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { getApi2 } from '@/api'
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: Number,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      info: {}
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getDetail()
      } else {
        this.info = {}
      }
    }
  },
  methods: {
    getDetail() {
      getApi2('/evaluation/appointment/counselling/detail', { id: this.id }).then(res => {
        this.info = res
      })
    },
    handleColseDialog() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    handleSubmit() {
      this.$emit('submit', this.info.create_type)
      this.handleColseDialog()
    },
    handleCancle() {
      this.$emit('cancel', this.info.create_type)
      this.handleColseDialog()
    },
    handleAccomplish() {
      this.$emit('accomplish', this.info.create_type)
      this.handleColseDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
.top-dialog {
  ::v-deep .el-dialog {
    width: 800rem !important;
    .el-dialog {
      width: 600rem !important;
    }
  }
}
.inner-info {
  position: relative;
  width: 60%;
  margin: auto;
  .status-img {
    position: absolute;
    top: 100rem;
    left: 160rem;
    width: 80rem;
    height: 71rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
